.container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-new-extraction {
    display: flex;
    position: fixed;
    background-color: white;
    border-radius: 9999px;
    border: none;
    cursor: pointer;
    bottom: 10%;
    right: 5%;
    width: 50px;
    height: 50px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.button-new-extraction:hover {
    background-color: rgba(249, 249, 249, 1);
}

.button-new-extraction:active {
    background-color: #f2f2f2;
}

.button-new-extraction div:nth-child(1) {
    position: relative;
    top: 50%;
    left: 20%;
    color: black;
    background-color: black;
    width: 60%;
    height: 1px;
}

.button-new-extraction div:nth-child(2) {
    position: relative;
    top: 20%;
    right: 12%;
    color: black;
    background-color: black;
    width: 1px;
    height: 60%;
}

.modal-header {
    display: flex;
    flex-direction: row;
}

.modal-header-input {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    align-items: center;
    border-radius: 15px;
    background-color: #f2f2f2;
    min-width: 200px;
    border: none;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 10px;
    width: 100%;
}

.modal-header-input input {
    font-size: 18px;
    border-radius: 15px;
    background-color: #f2f2f2;
    border: none;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    margin-right: 10px;
    width: 100%;
}

.modal-header-input-icon {
    margin-left: 15px;
    color: rgba(0, 0, 0, 0.5);
}


.modal-header-submit {
    font-size: 18px;
    width: 300px;
    border-radius: 15px;
    background-color: #010064;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px 50px;
}

.checkbox-france input[type="checkbox"],
.checkbox-cities input[type="checkbox"] {
    appearance: none; /* Supprime le style par défaut de la checkbox */
    -webkit-appearance: none; /* Compatibilité pour Safari */
    width: 20px; /* Taille de la checkbox */
    height: 20px;
    border: 2px solid black; /* Couleur des contours */
    border-radius: 4px; /* Arrondir les coins */
    cursor: pointer;
    display: inline-block; /* Permet un meilleur alignement */
    position: relative;
    background-color: white; /* Fond de la checkbox */
    margin-right: 8px; /* Espace entre la case et le texte */
}

.checkbox,
.checkbox-france,
.checkbox-cities {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 5px;
}

/* Style lorsque la checkbox est cochée */
.checkbox-france input[type="checkbox"]:checked,
.checkbox-cities input[type="checkbox"]:checked {
    background-color: #007bff; /* Couleur de fond lorsqu'elle est cochée */
    border-color: #0056b3; /* Couleur des contours */
}

/* Ajouter un symbole "check" lorsqu'elle est cochée */
.checkbox-france input[type="checkbox"]:checked::after,
.checkbox-cities input[type="checkbox"]:checked::after {
    content: "✔"; /* Symbole check */
    color: white;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Style pour l'effet hover */
.checkbox-france input[type="checkbox"]:hover,
.checkbox-cities input[type="checkbox"]:hover {
    border-color: #0056b3; /* Accentuer la couleur au survol */
}

.modal-body-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.modal-body-title {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;

    border: 2px solid rgba(0, 0, 0, 0.3);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.modal-body-title label {
    width: 100%;
    font-size: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 15px;
}

.department-selector {
    width: 100%;
}

.modal-body-selector {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: start;
    text-align: center;
    width: 100%;
}

.modal-body-selector label {
    width: 100%;
    font-size: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.city-selector {
    width: 100%;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 5vh;
}