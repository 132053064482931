body {
    background-color: #E4E3D8;
}

.app {
    display: flex;
    flex-direction: column;
    background-color: #e4e3d8;
    min-height: 100vh;
    min-width: 650px;
    font-family: DMSans-Regular, sans-serif;
}

.app-header {
    background-color: #010064;
    height: 94px;
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 10px;
}

.app-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #010064;
    background-color: #e4e3d8;
}

.app-footer {
    display: flex;
    text-align: center;
    justify-content: center;
    bottom: 0;
    margin-top: auto;
    color: #010064;
}

@media (max-width: 768px) {
    .app {
        max-height: 300vh;
        min-width: 300px;
    }

    .app-header {
        height: 70px;
        margin-bottom: 7px;
        font-size: 11px;
    }

    .app-footer {
        font-size: 12px;
        padding: 7px;
    }
}