.container-search {
    display: flex;
    max-width: 90%;
    min-height: 65vh;
    max-height: 75vh;
    justify-content: center;
    align-items: start;
    background: white;
    margin-top: 2%;
    overflow-y: auto;
}

.container-table {
    margin: 2%;
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 97%;
    max-width: 1200px;
    background-color: white;
    z-index: 0;
}


.table-thead {
    height: 10px;
}

.table-header-row {
    position: sticky;
    background-color: #b2dcff;
    font-weight: bold;
    width: 10px;
}

.table-header-cell {
    justify-content: center;
    text-align: center;
    align-items: center;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    overflow: visible;
    border-top: 1px solid rgba(1, 0, 100, 0.5);
    border-right: 1px solid rgba(1, 0, 100, 0.5);
    border-left: 1px solid rgba(1, 0, 100, 0.5);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
}

.header-left-round {
    border-radius: 5px 0px 0px 0px;
}

.header-right-round {
    border-radius: 0px 5px 0px 0px;
}

.table-tbody {
    width: 100%;
    max-height: 90%;
    background-color: #ffffff;
    box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.1),
    inset 0 -4px 6px rgba(0, 0, 0, 0.1),
    inset 4px 0 6px rgba(0, 0, 0, 0.1),
    inset -4px 0 6px rgba(0, 0, 0, 0.1);
}

.table-row {
    font-size: 14px;
    height: 45px;
}

.table-row:nth-of-type(odd) {
    background-color: #ffffff;
}

.table-row:nth-of-type(even) {
    background-color: #eaf5fd;
}


.table-cell {
    text-align: center;
    border-right: 1px solid rgba(1, 0, 100, 0.5);
    border-left: 1px solid rgba(1, 0, 100, 0.5);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
}

.download-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 30px;
    width: 100%;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #D2FA94;
    color: black;
}

.cell-left-round {
    border-radius: 5px 0px 0px 5px;
    border-left: 0px !important;
}

.cell-right-round {
    border-radius: 0px 5px 5px 0px;
    border-right: 0px !important;
}

.suppr-button {
    height: 30px;
    width: 90%;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #FA5252;
}


@media (max-width: 768px) {
    .container-search {
        min-height: 45vh;
        max-height: 55vh;
        margin-top: 1%;
    }

    .container-table {
        margin: 1%;
        border-spacing: 0 5px;
        width: 100%;
        max-width: 800px;
    }

    .table-thead {
        height: 10px;
    }

    .table-header-row {
        width: 15px;
    }

    .table-header-cell {
        width: 250px;
        font-size: 9px;
    }

    .header-left-round {
        border-radius: 2px 0px 0px 0px;
    }

    .header-right-round {
        border-radius: 0px 2px 0px 0px;
    }

    .table-tbody {
        width: 90%;
        max-height: 80%;
    }

    .table-row {
        font-size: 11px;
        height: 35px;
    }

    .download-button {
        font-size: 9px;
        height: 20px;
        border-radius: 3px;
    }

    .cell-left-round {
        border-radius: 3px 0px 0px 3px;
    }

    .cell-right-round {
        border-radius: 0px 3px 3px 0px;
    }

    .suppr-button {
        height: 20px;
        width: 75%;
        border-radius: 3px;
    }
}